import { render, staticRenderFns } from "./TransactionsMade.vue?vue&type=template&id=1c45ca77&scoped=true&"
import script from "./TransactionsMade.vue?vue&type=script&lang=js&"
export * from "./TransactionsMade.vue?vue&type=script&lang=js&"
import style0 from "./TransactionsMade.vue?vue&type=style&index=0&id=1c45ca77&lang=scss&scoped=true&"
import style1 from "./TransactionsMade.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c45ca77",
  null
  
)

export default component.exports